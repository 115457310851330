export const SERVER = `/api`;
export const ACCESS_TOKEN_KEY = 'hotrelease_at';
export function setCookie(name, value, days = 2) {
//   var expires = '';
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = '; expires=' + date.toUTCString();
//   }
//   document.cookie = name + '=' + (value || '') + expires + '; path=/; sameSite=None;';
  localStorage.setItem(name, value);
}
export function getCookie(name) {
//   var nameEQ = name + '=';
//   var ca = document.cookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
    return localStorage.getItem(name);
}
export function eraseCookie(name) {
//   document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.removeItem(name);
}
export const getAuthHeader = () => {
  const token = getCookie(ACCESS_TOKEN_KEY);
  return {
    headers: {
      authorization: token ? `JWT ${token}` : '',
    },
  };
};
