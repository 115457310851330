// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Create Post',
    path: '/dashboard/create-post',
    icon: getIcon('eva:file-add-outline'),
  },
  {
    title: 'Published Posts',
    path: '/dashboard/posts',
    icon: getIcon('eva:file-text-outline'),
  },
  {
    title: 'Posts In Review',
    path: '/dashboard/postsinreview',
    icon: getIcon('eva:eye-outline'),
    isProtected: true,
  },
  {
    title: 'Submitted Ideas',
    path: '/dashboard/ideas',
    icon: getIcon('eva:bulb-outline'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-outline'),
    isProtected: true,
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-2-outline'),
    isProtected: true,
  },
  {
    title: 'Docs',
    path: 'https://docs.updatebox.tech',
    icon: getIcon('eva:book-outline'),
    isExternal: true,
  },
];

export default navConfig;
