import { Alert, AppBar, Box, Divider, IconButton, Stack, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getAuthHeader, SERVER } from '../../../store/common/apiUtils';
import Iconify from '../../../components/Iconify';

const StyledColorBox = styled('div')({
  backgroundColor: '#ececec',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 16px',
  borderRadius: '4px',
  fontFamily: 'monospace',
  cursor: 'pointer',
});

const StyledColorSelector = styled('div')({
  position: 'absolute',
  zIndex: '2',
  bottom: '0',
});
const StyledCoverBox = styled('div')({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
});

export default function ColorSetter() {
  const orgData = useSelector((state) => state.auth.org);
  const [headerColor, setHeaderColor] = useState(orgData.headerColor);
  const [textColor, setTextColor] = useState(orgData.textColor);
  const [savingStyles, setSavingStyles] = useState(false);
  const [showHeaderColorSelector, setShowHeaderColorSelector] = useState(false);
  const [showTextColorSelector, setShowTextColorSelector] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(async () => {}, []);

  const saveColors = () => {
    setSavingStyles(true);
    const data = {
      headerColor,
      textColor,
      orgId: orgData._id,
    };
    
    axios
      .patch(`${SERVER}/organization/update`, data, getAuthHeader())
      .then((res) => {
        if (res.data.success) {
          setSavingStyles(false);
          setShowSuccess(true);
        } else {
          setSavingStyles(false);
          setHasError(res?.data?.message || 'Something went wrong');
        }
      })
      .catch((err) => {
        setSavingStyles(false);
        setHasError('Something went wrong');
      });
  };
  const handleChangeHeaderColor = (color) => {
    setHeaderColor(color.hex);
  };
  const handleChangeTextColor = (color) => {
    setTextColor(color.hex);
  };
  return (
    <Box sx={{ my: 2, mx: 2 }}>
      <Typography variant="h6">Set Colors for UpdateBox</Typography>
      <Divider sx={{ mt: 1 }} />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={3}
        alignItems={{ sm: 'start' }}
        sx={{ my: 2 }}
        justifyContent="space-between"
      >
        <Stack direction="column" alignItems="start" spacing={2}>
          <Typography variant="body2">SELECT HEADER COLOR</Typography>
          <StyledColorBox
            onClick={() => setShowHeaderColorSelector((t) => !t)}
            sx={{ borderBottom: `4px solid ${headerColor}` }}
          >
            {headerColor}
          </StyledColorBox>
          {showHeaderColorSelector && (
            <StyledColorSelector>
              <StyledCoverBox onClick={() => setShowHeaderColorSelector(false)} />
              <BlockPicker triangle="hide" color={headerColor} onChange={handleChangeHeaderColor} />
            </StyledColorSelector>
          )}

          <Typography variant="body2">SELECT TEXT COLOR</Typography>
          <StyledColorBox
            onClick={() => setShowTextColorSelector((t) => !t)}
            sx={{ borderBottom: `4px solid ${textColor}` }}
          >
            {textColor}
          </StyledColorBox>
          {showTextColorSelector && (
            <StyledColorSelector>
              <StyledCoverBox onClick={() => setShowTextColorSelector(false)} />
              <BlockPicker triangle="hide" color={textColor} onChange={handleChangeTextColor} />
            </StyledColorSelector>
          )}

          <LoadingButton size="large" type="submit" variant="contained" loading={savingStyles} onClick={saveColors}>
            Save Colors
          </LoadingButton>
        </Stack>
        <AppBar
          position="static"
          sx={{
            backgroundColor: headerColor,
            color: textColor,
            maxWidth: '480px',
          }}
        >
          <Toolbar>
            <Typography variant="subtitle1" textTransform={'uppercase'} component="div" sx={{ flexGrow: 1 }}>
              {orgData.name}
            </Typography>
            <IconButton color="inherit">
              <Iconify icon="eva:close-outline" />
            </IconButton>
          </Toolbar>
          <Tabs value={0} TabIndicatorProps={{ style: { background: textColor } }} textColor="inherit">
            <Tab label="UPDATES" />
            {orgData.showFeatureRequest && <Tab label="FEATURE SUGGESTION" />}
          </Tabs>
        </AppBar>
      </Stack>
      {hasError && (
        <Alert severity="error" variant="filled" sx={{ mx: 2 }}>
          {hasError}
        </Alert>
      )}
      {showSuccess && (
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          Colors saved successfully
        </Alert>
      )}
    </Box>
  );
}
